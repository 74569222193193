import React, {
    createContext,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import {pageName, usePromise, Loader} from 'shared/helpers';
import {useConfirmationDialog} from 'shared';
import {getJob} from 'service';
import {useNavigate, useLocation} from 'react-router-dom';
import {cloneDeep, isEqual} from 'lodash';
import useUI from 'shared/useUI';
import {isBenchtopPage, isHardwarePage} from 'shared/helpers/routeUtils';
import {useAppContext} from 'contexts';

export const JobContext = createContext();

export const useJobContext = () => useContext(JobContext);

export const JobProvider = ({children, jobId, roomId}) => {
    const {setIsLeftDashboardButtonVisible, userProfile} = useAppContext();
    const {
        setIsCartToggleVisible,
        restoreLeftSidebarBehaviour,
        restoreRightSidebarBehaviour,
    } = useUI();
    const {showDialog, dialog, hideDialog} = useConfirmationDialog();
    const navigate = useNavigate();
    const location = useLocation();

    const [isJobProcessing, setIsJobProcessing] = useState(true);
    const [job, setJob] = useState({});
    const [loader, setLoader] = useState(false);
    const [forceRefresh, refresh] = useState({});
    const [room, setRoom] = useState({});
    const [isPageDirty, setIsPageDirty] = useState([]);

    useEffect(() => {
        if (location.state != null && location.state.hasOwnProperty('room')) {
            setRoom(location.state.room);
        }
    }, [location.state]);

    const expiredJobPrice = useMemo(() => {
        return job.priceExpire;
    }, [job]);

    useEffect(() => {
        if (job && job.rooms && job.rooms.length) {
            let roomIndex;
            const currentRoom = job.rooms.find((room_, index) => {
                if (room.id == room_.id) {
                    roomIndex = index;
                    return true;
                }

                return false;
            });

            if (
                currentRoom &&
                typeof roomIndex !== 'undefined' &&
                !isEqual(currentRoom, room)
            ) {
                setJob((job) => {
                    const rooms = cloneDeep(job.rooms);
                    rooms[roomIndex] = room;

                    return {
                        ...job,
                        ...{
                            rooms,
                        },
                    };
                });
            }
        }
    }, [room]);

    useEffect(() => {
        setIsJobProcessing(true);
        if (!forceRefresh.hasOwnProperty('toggleSidebar')) {
            const page = pageName(location.pathname);
            if (page === 'job' && jobId) {
                setIsLeftDashboardButtonVisible(false);
                setIsCartToggleVisible(true);
                restoreLeftSidebarBehaviour(null);
                restoreRightSidebarBehaviour(null);
            } else {
                setIsLeftDashboardButtonVisible(false);
            }
        }

        if (jobId && !isNaN(jobId)) {
            setLoader(true);

            const setNewRoom =
                forceRefresh.hasOwnProperty('setNewRoom') &&
                forceRefresh.setNewRoom;
            const persistRoom =
                forceRefresh.hasOwnProperty('persistRoom') &&
                forceRefresh.persistRoom;
            const currentRoom = roomId
                ? roomId
                : persistRoom
                ? persistRoom
                : room
                ? room.id
                : false;

            const jobDetailsPromise = getJob(parseInt(jobId) - 10000);

            return usePromise(
                ([jobDetails]) => {
                    if (typeof jobDetails != 'undefined') {
                        setIsJobProcessing(jobDetails.status == 0);
                        let roomDetails;
                        if (jobDetails.rooms.length) {
                            roomDetails = jobDetails.rooms.find(
                                (room) => room.id == currentRoom
                            );

                            if (typeof roomDetails === 'undefined') {
                                let index = 0;
                                if (setNewRoom) {
                                    index = jobDetails.rooms.length - 1;
                                }

                                roomDetails = jobDetails.rooms[index];
                            }
                        } else {
                            setRoom({});
                            if (
                                !isHardwarePage(location.pathname) &&
                                !isBenchtopPage(location.pathname)
                            ) {
                                navigate(
                                    `/v2/job/${jobDetails.displayId}/dashboard`
                                );
                            }
                        }

                        setJob(jobDetails);
                        setRoom(
                            typeof roomDetails === 'undefined'
                                ? {}
                                : roomDetails
                        );
                    }
                    setLoader(false);
                },
                [jobDetailsPromise],
                (error) => {
                    setLoader(false);
                    setJob({error});
                },
                () => {
                    setLoader(false);
                }
            );
        }
    }, [forceRefresh, jobId]);

    const actionButtonsEnabled = useMemo(() => {
        const enableButton =
            isJobProcessing && userProfile?.inActiveManufacturer == 0;
        const expiredJob = !userProfile.show_pricing ? false : expiredJobPrice;

        return enableButton && !expiredJob;
    }, [isJobProcessing, userProfile, expiredJobPrice]);

    return (
        <JobContext.Provider
            value={{
                job,
                setJob,
                refresh,
                room,
                setRoom,
                isPageDirty,
                setIsPageDirty,
                showDialog,
                hideDialog,
                isJobProcessing,
                actionButtonsEnabled,
                setLoader,
                expiredJobPrice,
            }}>
            <Loader loader={loader}>{children}</Loader>
            {dialog}
        </JobContext.Provider>
    );
};
